import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 33 36",
    xmlns: "http://www.w3.org/2000/svg",
    ...a
  },
  /* @__PURE__ */ e.createElement("path", { d: "M4 33.667a3.208 3.208 0 0 1-2.353-.979 3.211 3.211 0 0 1-.98-2.355V3.667c0-.917.326-1.702.98-2.355A3.208 3.208 0 0 1 4 .333h11.958a3.298 3.298 0 0 1 2.334.959l8.083 8.083a3.297 3.297 0 0 1 .958 2.333v8.187A14.405 14.405 0 0 0 24 19.26V12h-6.667c-.472 0-.867-.16-1.186-.48a1.609 1.609 0 0 1-.48-1.187V3.667H4v26.666h5.665c.07.327.189.695.391 1.068.404.81.874 1.566 1.408 2.266H4ZM20.567 30.366c.485.467 1.073.7 1.766.7.693 0 1.282-.233 1.768-.7a2.27 2.27 0 0 0 .727-1.7c0-.666-.243-1.233-.727-1.7-.486-.466-1.075-.7-1.768-.7-.692 0-1.281.234-1.766.7a2.272 2.272 0 0 0-.728 1.7c0 .667.243 1.234.728 1.7Z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.467 33.7c1.77 1.089 3.726 1.633 5.866 1.633 2.14 0 4.096-.544 5.866-1.633 1.77-1.089 3.11-2.56 4.019-4.411a.704.704 0 0 0 .092-.278 2.496 2.496 0 0 0 0-.69.702.702 0 0 0-.092-.277 10.523 10.523 0 0 0-4.019-4.41C26.43 22.544 24.473 22 22.333 22c-2.14 0-4.095.545-5.866 1.634a10.522 10.522 0 0 0-4.018 4.41.702.702 0 0 0-.093.278 2.516 2.516 0 0 0 0 .689.704.704 0 0 0 .093.278 10.529 10.529 0 0 0 4.018 4.411Zm8.811-2.2c-.808.778-1.79 1.167-2.945 1.167-1.154 0-2.136-.39-2.945-1.167-.808-.778-1.212-1.722-1.212-2.833 0-1.112.404-2.056 1.212-2.834.809-.778 1.79-1.166 2.945-1.166 1.155 0 2.137.388 2.945 1.166.808.778 1.212 1.723 1.212 2.834 0 1.11-.404 2.055-1.212 2.833Z"
    }
  )
);
export {
  t as default
};
