import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M17.877 18.377c-.3.299-.784.299-1.084 0l-4.291-4.292v1.75c0 .46-.373.833-.834.833H8.335a.833.833 0 0 1-.833-.833v-4.167H6.18c-.742 0-1.114-.897-.589-1.422l1.577-1.578-5.702-5.627a.763.763 0 1 1 1.076-1.083l15.335 15.335c.299.3.299.784 0 1.084ZM15.002 5a.833.833 0 1 0 0-1.666h-9L7.668 5h7.334Zm-.667 6.667c.554 0 .83-.67.44-1.06L10.59 6.423a.833.833 0 0 0-1.179 0l-.16.16 5.083 5.084Z" })
);
export {
  a as default
};
