import * as a from "react";
const e = (c) => /* @__PURE__ */ a.createElement(
  "svg",
  {
    viewBox: "0 0 21 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ a.createElement("path", { d: "M3.833 9.167a.806.806 0 0 1-.593-.24.806.806 0 0 1-.24-.594v-5c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h5c.236 0 .434.08.594.24.16.16.24.357.24.593v5c0 .236-.08.434-.24.594a.806.806 0 0 1-.594.24h-5Zm8.334 0a.806.806 0 0 1-.594-.24.806.806 0 0 1-.24-.594v-5c0-.236.08-.434.24-.593.16-.16.358-.24.594-.24h5c.236 0 .434.08.593.24.16.16.24.357.24.593v5c0 .236-.08.434-.24.594a.806.806 0 0 1-.593.24h-5ZM3.833 17.5a.806.806 0 0 1-.593-.24.806.806 0 0 1-.24-.593v-5c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h5c.236 0 .434.08.594.24.16.16.24.358.24.594v5c0 .236-.08.434-.24.593a.806.806 0 0 1-.594.24h-5Zm10.834 0a.807.807 0 0 1-.594-.24.806.806 0 0 1-.24-.593V15h-1.687a.772.772 0 0 1-.584-.24.825.825 0 0 1-.229-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24h1.666v-1.687c0-.236.08-.43.24-.584a.826.826 0 0 1 .594-.229c.236 0 .434.08.593.24.16.16.24.358.24.594v1.666h1.688c.236 0 .43.08.583.24.153.16.229.358.229.594s-.08.434-.24.593a.806.806 0 0 1-.593.24H15.5v1.688c0 .236-.08.43-.24.583a.825.825 0 0 1-.593.229Z" })
);
export {
  e as default
};
