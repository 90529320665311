/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from "react";
import styled, { css } from "styled-components";
import { ButtonProps } from "./Button.types";
import { buttonVariants, buttonSizes } from "./Button.styles";
import { ButtonLoader } from "./ButtonLoader";

const ButtonIconWrapper = styled.span({
  display: "inline-flex",
  alignSelf: "center",
  flexShrink: 0,
  width: "20px",
  height: "20px",
});

const ButtonCore = styled.button<{
  size: "sm" | "lg";
  iconButton?: boolean;
  variant: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  svg {
    fill: currentColor;
    width: 20px;
    height: auto;
  }

  border-radius: var(--radii-md);
  appearance: auto;
  transition: var(--transition-button);
  cursor: pointer;

  ${({ size }) => css`
    ${buttonSizes[size]}
  `}

  ${({ variant }) => css`
    ${buttonVariants[variant]}
  `}
  
  ${({ size, iconButton }) =>
    iconButton &&
    `
    width: ${size === "sm" ? "32px" : "40px"};
  `}

  // override button:disabled css in styleguide.min.css (#FE-131)
  &&:disabled {
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
`;

const capitalizeFirstLetter = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

const noop = () => {};

export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const {
      size = "lg",
      colorScheme = "active",
      disabled = false,
      iconButton = false,
      loading = false,
      type = "button",
      leadingIcon,
      trailingIcon,
      children,
      onClick,
      variant = "primary",
      iconPosition,
      ...rest
    } = props;

    const buttonStyleVariant = `${colorScheme}${capitalizeFirstLetter(
      variant
    )}`; // e.g. "activePrimary"

    return (
      <ButtonCore
        iconButton={iconButton}
        ref={ref}
        disabled={disabled}
        type={type}
        size={size}
        variant={buttonStyleVariant}
        onClick={loading ? noop : onClick}
        aria-disabled={loading}
        {...rest}
      >
        {!iconButton && leadingIcon && !loading && (
          <ButtonIconWrapper style={{ marginRight: "var(--space-xs-plus" }}>
            {leadingIcon}
          </ButtonIconWrapper>
        )}
        {loading ? <ButtonLoader /> : children}
        {!iconButton && trailingIcon && !loading && (
          <ButtonIconWrapper style={{ marginLeft: "var(--space-xs-plus)" }}>
            {trailingIcon}
          </ButtonIconWrapper>
        )}
      </ButtonCore>
    );
  }
);
