import * as React from "react";

const SvgIconTextHighlight = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m7.658 8.116 5.045 5.078-3.737 3.744c-.374.375-.814.562-1.32.562-.506 0-.946-.187-1.32-.562l-.117.094c-.171.14-.358.25-.56.328a1.74 1.74 0 0 1-.631.117h-2.85c-.218 0-.37-.094-.456-.281-.085-.187-.05-.359.105-.515l2.15-2.13a1.923 1.923 0 0 1-.584-1.345 1.758 1.758 0 0 1 .537-1.346l3.738-3.744Zm1.308-1.334 3.737-3.72c.374-.375.814-.562 1.32-.562.507 0 .946.187 1.32.562l2.43 2.41c.373.374.56.815.56 1.322 0 .507-.186.948-.56 1.322l-3.738 3.744-5.07-5.078Z" />
  </svg>
);
export default SvgIconTextHighlight;
